import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import CreatorForm from "./Components/CreatorForm";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LogoPage />} />
        <Route path="/:username" element={<UserPage />} />
        <Route path="/creators" element={<CreatorForm />} />
      </Routes>
    </Router>
  );
}

function LogoPage() {
  return (
    <div className="main-container">
      <style>{styles}</style>

      <header className="header">
        <div className="header-container">
          <div className="header-icon"></div>
          <h1 className="header-title">Telegram</h1>
        </div>
      </header>

      {/* You can add more content here if needed */}
    </div>
  );
}

function UserPage() {
  const { username } = useParams();

  const handleSendMessage = () => {
    window.location.href = `https://t.me/${username}`;
  };

  return (
    <div className="main-container">
      <style>{styles}</style>

      <header className="header">
        <div className="header-container">
          <div className="header-icon"></div>
          <h1 className="header-title">Telegram</h1>
        </div>
      </header>

      <div className="card">
        <h1 className="name">@{username}</h1>
        <button onClick={handleSendMessage} className="button">
          SEND MESSAGE
        </button>
      </div>
    </div>
  );
}

const styles = `
  :root {
    --card-bg: white;
    --text-color: #000000;
    --text-secondary: #787A7C;
    --text-tertiary: #000000;
    --button-gradient: linear-gradient(180deg, #006FC9 0%, #006FC9 100%);
    --button-hover-gradient: linear-gradient(180deg, #006FC9 0%, #00569c 100%);
    --background-text-opacity: 0.2;
    --bg-gradient: linear-gradient(180deg, #C5CF9E 0%, #579E73 100%);
    --header-bg: #ffffff;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --card-bg: #1E1E1E;
      --text-color: #ffffff;
      --text-secondary: #787A7C;
      --text-tertiary: #ffffff;
      --button-gradient: linear-gradient(180deg, #1C93E3 0%, #1C93E3 100%);
      --button-hover-gradient: linear-gradient(180deg, #1C93E3 0%, #00569c 100%);
      --background-text-opacity: 0.2;
      --bg-gradient: linear-gradient(180deg, #000000 0%, #000000 100%);
      --header-bg: #1E1E1E;
    }
  }

  body {
    margin: 0;
    font-family: 'Lexend', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-gradient);
    min-height: 100vh;
  }

  .header {
    width: 100%;
    background: var(--header-bg);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .header-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 10px 20px 10px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-icon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/telegraml.svg');
  }

  @media (prefers-color-scheme: dark) {
    .header-icon {
      background-image: url('/telegramd.svg');
    }
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-tertiary);
    margin: 0;
  }

  .card {
    position: relative;
    background: var(--card-bg);
    margin-top: 80px;
    padding: 32px 0px 22px 0px;
    border-radius: 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    text-align: center;
    width: min(100%, 340px);
  }

  .name {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.9px;
    margin: 0 0 24px 0;
    color: var(--text-color);
  }

  .button {
    background-image: var(--button-gradient);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
  }

  .button:hover {
    background-image: var(--button-hover-gradient);
  }
`;

export default App;
